<template>
  <div>
    <div class="px-4">
      <p>
        Add and update contact information for the important people in your
        life.
      </p>
      <p>
        Having the correct details for your key contacts, including your
        beneficiaries, will help your Executor when it’s time to execute your
        wishes.
      </p>
      <p>
        If you’d like your Executor to notify particular people of your passing,
        add their details here.
      </p>
    </div>
    <div class="d-flex align-center pb-2 px-4">
      <h3>Your Contacts</h3>
      <v-spacer />
      <v-btn
        text
        class="bg-grey-20 radius-4"
        @click="open()"
        ><v-icon
          color="primary"
          left
          >mdi-plus-thick</v-icon
        >Add</v-btn
      >
    </div>
    <div class="divider-light" />
    <transition-group
      name="component-fade"
      mode="out-in"
      v-if="entities_people.length > 0"
    >
      <v-list-item
        v-for="item in peopleList"
        :key="item.id"
        class="border-divider-light"
        tile
      >
        <v-list-item-avatar
          class="people-avatar"
          size="36"
        >
          <v-icon
            size="22"
            color="black"
            >mdi-account-outline</v-icon
          >
        </v-list-item-avatar>
        <v-list-item-content>
          <div class="font-weight-bold my-2">{{ item.name }}</div>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            text
            small
            class="bg-grey-30 py-4 radius-4"
            @click="open(item.id)"
            ><v-icon
              left
              color="primary"
              >mdi-pencil-outline</v-icon
            >Edit</v-btn
          >
        </v-list-item-action>
        <v-list-item-action>
          <v-btn
            icon
            color="black"
            height="32"
            class="bg-grey-30 radius-4"
            @click="deletePersonConfirm(item.id)"
            ><v-icon>mdi-trash-can-outline</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </transition-group>

    <div
      v-else
      class="pa-4"
    >
      <div class="text-no-result">
        <p>There are no people added to your account.</p>
        <div>
          When you add people to your Will you will see them listed here.
        </div>
      </div>
    </div>
    <v-dialog
      v-model="deleteDialog"
      :max-width="500"
      persistent
    >
      <v-card>
        <v-list-item
          dense
          class="bg-sand"
        >
          <v-list-item-content>
            <h3 class="dialog-title">Delete Person</h3>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="deleteDialog = false"
              ><v-icon color="black">mdi-close</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="py-4">
          <v-list-item-content>
            <div class="font-weight-bold mb-4">
              Would you like to delete
              {{ $store.getters['account/entity_person'](personId).name }} from
              your account?
            </div>
          </v-list-item-content>
        </v-list-item>
        <div class="divider" />
        <v-btn
          text
          tile
          x-large
          block
          @click="deletePerson(personId)"
          ><v-icon
            left
            color="error"
            >mdi-trash-can-outline</v-icon
          >Delete</v-btn
        >
        <div class="divider" />
        <v-btn
          text
          tile
          block
          x-large
          class="bg-grey-10"
          @click="deleteDialog = false"
          >Cancel</v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteAlert"
      :max-width="500"
      persistent
    >
      <v-card>
        <v-list-item
          dense
          class="bg-sand"
        >
          <v-list-item-avatar class="mr-2">
            <v-icon color="warning">mdi-alert</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <h3 class="dialog-title">Warning</h3>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="deleteAlert = false"
              ><v-icon color="black">mdi-close</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="py-4">
          <v-list-item-content>
            <div class="font-weight-bold mb-4">
              {{ $store.getters['account/entity_person'](personId).name }} is
              included in your {{ cantDeleteBecause }}.
            </div>
            <div class="mb-4">
              Before you can delete this contact you must remove them from your
              Will and Messages.
            </div>
          </v-list-item-content>
        </v-list-item>
        <div class="divider" />
        <v-btn
          text
          tile
          block
          x-large
          class="bg-grey-10"
          @click="deleteAlert = false"
          >OK</v-btn
        >
      </v-card>
    </v-dialog>
    <dialog-full
      :open.sync="dialogOpen"
      :title="dialogTitle"
      path="/contacts"
      base-path="/vault"
    >
      <person
        v-if="openForm"
        @close="close"
        @next="close"
        :id="personId"
        :editable="{
          address: true,
          email: true,
          phone: true,
          details: true,
          occupation: true,
          dob: true,
        }"
        close-label="Cancel"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Person from '../../../components/form/Person'
import DialogFull from '../../../components/wrapper/DialogFull'

export default {
  name: 'VaultContacts',
  components: { Person, DialogFull },
  data: () => ({
    openForm: null,
    personId: null,
    deleteDialog: false,
    deleteAlert: false,
    cantDeleteBecause: '',
  }),
  methods: {
    close() {
      this.openForm = null
      this.personId = null
    },
    open(id = null) {
      this.personId = id
      this.openForm = 'person'
    },
    async deletePersonConfirm(id) {
      this.personId = id
      const [validPerson, validPoa] = await Promise.all([
        this.$store.dispatch('account/validate_entity_person_for_delete', id),
        this.$store.dispatch(
          'account/validate_entity_person_for_delete_poa',
          id
        ),
      ])
      this.cantDeleteBecause =
        !validPerson && !validPoa
          ? 'Will or Messages and your POA documents'
          : !validPerson
            ? 'Will or Messages'
            : 'POA documents'
      this[validPerson && validPoa ? 'deleteDialog' : 'deleteAlert'] = true
    },
    async deletePerson(id) {
      this.deleteDialog = false
      await this.$store.dispatch('account/delete_entity', id)
    },
  },
  computed: {
    ...mapGetters('account', ['entities_people']),
    peopleList() {
      return this.entities_people
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      },
    },
    dialogTitle() {
      return this.personId ? 'Edit Person' : 'Add Person'
    },
  },
}
</script>

<style lang="scss">
.people-avatar {
  background-color: var(--primary-10);
}
.dialog-title {
  font-family: CooperBT;
}
</style>
